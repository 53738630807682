import { object, string, array, lazy, mixed } from 'yup'
import { t } from '@lingui/macro'

const sectionValidator = (i18n) =>
  Object.freeze({
    accordion: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      items: array()
        .of(
          object()
            .shape({
              title: string()
                .max(120, i18n._(t`Judul maksimal 120 karakter`))
                .required(i18n._(t`Judul harus diisi!`)),
              content: string()
                .max(2000, i18n._(t`Konten maksimal 2000 karakter`))
                .required(i18n._(t`Konten harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 item harus diisi!`))
    }),
    audio: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      audio: string().required(i18n._(t`Audio harus diisi!`))
    }),
    file: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      file: string().required(i18n._(t`File harus diisi!`))
    }),
    'grid-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      images: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`Link URL gambar harus diisi!`)),
              linkURL: string()
                .url('Make sure link URL is valid')
                .nullable(true)
                .default(null)
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
        .test({
          message: 'Image count must be multiply of 3',
          test: (value) => value.length > 0 && value.length % 3 === 0
        })
    }),
    'image-carousel': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      images: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`Link URL gambar harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
        .max(10, i18n._(t`Maksimal 10 gambar`))
    }),
    'link-button': object().shape({
      buttonLabel: string()
        .max(60, i18n._(t`Label tombol maksimal 60 karakter`))
        .required(i18n._(t`Label tombol harus diisi!`)),
      buttonLinkURL: string()
        .url(i18n._(t`Pastikan link URL tombol valid!`))
        .required(i18n._(t`Link URL tombol harus diisi!`))
    }),
    'list-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      images: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`Link URL gambar harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
    }),
    'plain-text': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      alignment: string()
        .oneOf(
          ['left', 'right', 'center', 'justify'],
          i18n._(t`Pastikan alignment valid!`)
        )
        .default('left')
        .required(i18n._(t`Alignment harus diisi!`)),
      subtitle: string()
        .max(240, i18n._(t`Subtitle maksimal 240 karakter`))
        .nullable(true)
        .default(null),
      content: string()
        .max(25000, i18n._(t`Konten maksimal 25000 karakter`))
        .required(i18n._(t`Konten harus diisi!`))
    }),
    'single-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      imageURL: string()
        .transform((v) => v || null)
        .required(i18n._(t`Link URL gambar harus diisi!`)),
      buttonLabel: string()
        .max(60, i18n._(t`Label tombol maksimal 60 karakter`))
        .nullable(true)
        .default(null),
      buttonLinkURL: string()
        .url(i18n._(t`Pastikan link URL tombol valid!`))
        .nullable(true)
        .default(null)
    }),
    video: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      video: string().required(i18n._(t`Video harus diisi!`))
    }),
    'whatsapp-button': object().shape({
      buttonLabel: string()
        .max(60, i18n._(t`Label tombol maksimal 60 karakter`))
        .required('Button label is required!'),
      whatsAppURL: string()
        .max(60, i18n._(t`WhatsApp link maksimal 60 karakter`))
        .required(i18n._(t`Pastikan URL Whatsapp valid`)) // this should only validate path after wa.me, i.e. wa.me/[VALUE TO VALIDATE]
    }),
    'youtube-video': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      videoURL: string()
        .url(i18n._(t`Pastikan link URL video valid!`))
        .required(i18n._(t`Link URL video harus diisi!`))
    }),
    'youtube-playlist': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable()
        .default(null),
      list: array()
        .of(
          object().shape({
            type: string().oneOf(['youtube', 'link', 'file']),
            title: string()
              .max(120, i18n._(t`Judul maksimal 120 karakter`))
              .required(i18n._(t`Judul harus diisi!`)),
            youtubeURL: string().when('type', {
              is: 'youtube',
              then: () =>
                string()
                  .nullable()
                  .url(i18n._(t`Pastikan link URL video valid!`))
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            }),
            linkURL: string().when('type', {
              is: 'link',
              then: () =>
                string()
                  .nullable()
                  .url(i18n._(t`URL Link tidak valid`))
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            }),
            file: string().when('type', {
              is: 'file',
              then: () =>
                string()
                  .nullable()
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            })
          })
        )
        .test(
          'max-50-link',
          i18n._(t`Maksimal 50 link untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'link').length <= 50
        )
        .test(
          'max-50-audio',
          i18n._(t`Maksimal 50 video untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'youtube').length <= 50
        )
        .test(
          'max-5-file',
          i18n._(t`Maksimal 2 file untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'file').length <= 5
        )
        .required(i18n._(t`Minimal satu item harus diisi dalam daftar`))
        .min(1, i18n._(t`Minimal satu item harus diisi dalam daftar`))
    }),
    'audio-playlist': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      list: array()
        .of(
          object().shape({
            type: string().oneOf(['audio', 'link', 'file']),
            title: string()
              .max(120, i18n._(t`Judul maksimal 120 karakter`))
              .required(i18n._(t`Judul harus diisi!`)),
            audio: string().when('type', {
              is: 'audio',
              then: () =>
                string()
                  .nullable()
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            }),
            imageURL: string().when('type', {
              is: 'audio',
              then: () =>
                string()
                  .transform((v) => v || null)
                  .nullable(),
              otherwise: () => string().transform(() => undefined)
            }),
            linkURL: string().when('type', {
              is: 'link',
              then: () =>
                string()
                  .nullable()
                  .url(i18n._(t`URL Link tidak valid`))
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            }),
            file: string().when('type', {
              is: 'file',
              then: () =>
                string()
                  .nullable()
                  .transform((v) => v || null),
              otherwise: () => string().transform(() => undefined)
            })
          })
        )
        .test(
          'max-20-link',
          i18n._(t`Maksimal 20 link untuk setiap Audio playlist`),
          (value) => value.filter(({ type }) => type === 'link').length <= 20
        )
        .test(
          'max-10-audio',
          i18n._(t`Maksimal 10 audio untuk setiap Audio playlist`),
          (value) => value.filter(({ type }) => type === 'audio').length <= 10
        )
        .test(
          'max-20-file',
          i18n._(t`Maksimal 20 file untuk setiap Audio playlist`),
          (value) => value.filter(({ type }) => type === 'file').length <= 20
        )
        .required(i18n._(t`Minimal satu item harus diisi dalam daftar`))
        .min(1, i18n._(t`Minimal satu item harus diisi dalam daftar`))
    }),
    'video-playlist': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      list: array()
        .of(
          object()
            .shape({
              type: string().oneOf(['video', 'link', 'file']),
              title: string()
                .max(120, i18n._(t`Judul maksimal 120 karakter`))
                .required(i18n._(t`Judul harus diisi!`)),
              video: string()
                .when('type', {
                  is: 'video',
                  then: () => string().required(i18n._(t`Video harus diisi`))
                })
                .transform((v) => v || null),

              linkURL: string()
                .when('type', {
                  is: 'link',
                  then: () =>
                    string()
                      .url(i18n._(t`URL Link tidak valid`))
                      .nullable()
                })
                .transform(() => undefined),
              file: string()
                .when('type', {
                  is: 'file',
                  then: () => string().nullable()
                })
                .transform(() => undefined)
            })
            .transform((v) => v || null)
        )
        .test(
          'max-20-link',
          i18n._(t`Maksimal 20 link untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'link').length <= 20
        )
        .test(
          'max-5-video',
          i18n._(t`Maksimal 5 video untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'video').length <= 5
        )
        .test(
          'max-20-file',
          i18n._(t`Maksimal 20 file untuk setiap Video playlist`),
          (value) => value.filter(({ type }) => type === 'file').length <= 20
        )

        .required(i18n._(t`Minimal satu item harus diisi dalam daftar`))
        .min(1, i18n._(t`Minimal satu item harus diisi dalam daftar`))
    }),
    'class-training': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      sessions: array()
        .of(
          object().shape({
            title: string()
              .max(120, i18n._(t`Judul maksimal 120 karakter`))
              .required(i18n._(t`Judul harus diisi!`)),
            list: array().of(
              object().shape({
                type: string().oneOf([
                  'content',
                  'file',
                  'link',
                  'image',
                  'youtube',
                  'video'
                ]),

                title: string().max(
                  120,
                  i18n._(t`Judul maksimal 120 karakter`)
                ),
                value: string()
                  .when('type', {
                    is: 'content',
                    then: () =>
                      string()
                        .max(25000, i18n._(t`Konten maksimal 25000 karakter`))
                        .required(i18n._(t`Konten harus diisi!`)),
                    otherwise: () => string().transform(() => undefined)
                  })
                  .transform((v) => v || null),
                linkURL: string().when('type', {
                  is: 'link',
                  then: () =>
                    string()
                      .nullable()
                      .url(i18n._(t`URL Link tidak valid`))
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                file: string().when('type', {
                  is: 'file',
                  then: () =>
                    string()
                      .nullable()
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                youtubeURL: string().when('type', {
                  is: 'youtube',
                  then: () =>
                    string()
                      .nullable()
                      .url(i18n._(t`URL Youtube tidak valid`))
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                imageURL: string().when('type', {
                  is: 'image',
                  then: () =>
                    string()
                      .transform((v) => v || null)
                      .nullable(),

                  otherwise: () => string().transform(() => undefined)
                }),
                video: string()
                  .when('type', {
                    is: 'video',
                    then: () =>
                      string()
                        .nullable()
                        .transform((v) => v || null),
                    otherwise: () => string().transform(() => undefined)
                  })
                  .transform((v) => v || null)
              })
            )
          })
        )
        .test(
          'max-20-link',
          i18n._(t`Maksimal 20 link untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'link').length <= 20
        )
        .test(
          'max-20-video',
          i18n._(t`Maksimal 20 video untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'youtube').length <= 20
        )
        .test(
          'max-20-file',
          i18n._(t`Maksimal 20 file untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'file').length <= 20
        )
        .test(
          'max-20-image',
          i18n._(t`Maksimal 20 gambar untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'image').length <= 20
        )
        .test(
          'max-20-content',
          i18n._(t`Maksimal 20 konten untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'content').length <= 20
        )
        .test(
          'max-20-video',
          i18n._(t`Maksimal 20 video untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'video').length <= 20
        )
        .max(20, i18n._(t`Maksimal 20 konten untuk setiap Kelas Pelatihan`))
    }),
    'class-training-v2': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      sessions: array()
        .of(
          object().shape({
            title: string()
              .max(120, i18n._(t`Judul maksimal 120 karakter`))
              .required(i18n._(t`Judul harus diisi!`)),
            list: array().of(
              object().shape({
                type: string().oneOf([
                  'content',
                  'file',
                  'link',
                  'image-swipe',
                  'youtube',
                  'video'
                ]),
                // title: string().when('type', {
                //   is: 'content',
                //   then: () =>
                //     string().max(120, i18n._(t`Judul maksimal 120 karakter`))
                // }),
                title: string()
                  .max(120, i18n._(t`Judul maksimal 120 karakter`))
                  .required(i18n._(t`Judul harus diisi!`)),
                value: string()
                  .when('type', {
                    is: 'content',
                    then: () =>
                      string()
                        .max(25000, i18n._(t`Konten maksimal 25000 karakter`))
                        .required(i18n._(t`Konten harus diisi!`)),
                    otherwise: () => string().transform(() => undefined)
                  })
                  .transform((v) => v || null),
                linkURL: string().when('type', {
                  is: 'link',
                  then: () =>
                    string()
                      .nullable()
                      .url(i18n._(t`URL Link tidak valid`))
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                file: string().when('type', {
                  is: 'file',
                  then: () =>
                    string()
                      .nullable()
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                youtubeURL: string().when('type', {
                  is: 'youtube',
                  then: () =>
                    string()
                      .nullable()
                      .url(i18n._(t`URL Youtube tidak valid`))
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                imageList: array().when('type', {
                  is: 'image-swipe',
                  then: () =>
                    array().of(
                      object().shape({
                        imageURL: string()
                          .transform((v) => v || null)
                          .nullable()
                      })
                    ),
                  otherwise: () => string().transform(() => undefined)
                }),
                video: string()
                  .when('type', {
                    is: 'video',
                    then: () =>
                      string()
                        .nullable()
                        .transform((v) => v || null),
                    otherwise: () => string().transform(() => undefined)
                  })
                  .transform((v) => v || null)
              })
            )
          })
        )
        .test(
          'max-20-link',
          i18n._(t`Maksimal 20 link untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'link').length <= 20
        )
        .test(
          'max-20-video',
          i18n._(t`Maksimal 20 video untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'youtube').length <= 20
        )
        .test(
          'max-20-file',
          i18n._(t`Maksimal 20 file untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'file').length <= 20
        )
        .test(
          'max-20-image',
          i18n._(t`Maksimal 20 gambar untuk setiap Kelas Pelatihan`),
          (value) =>
            value.filter(({ type }) => type === 'image-swipe').length <= 20
        )
        .test(
          'max-20-content',
          i18n._(t`Maksimal 20 konten untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'content').length <= 20
        )
        .test(
          'max-20-video',
          i18n._(t`Maksimal 20 video untuk setiap Kelas Pelatihan`),
          (value) => value.filter(({ type }) => type === 'video').length <= 20
        )
        .max(20, i18n._(t`Maksimal 20 konten untuk setiap Kelas Pelatihan`))
    }),
    'story-chapter': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      chapters: array()
        .of(
          object().shape({
            title: string()
              .max(120, i18n._(t`Judul maksimal 120 karakter`))
              .required(i18n._(t`Judul harus diisi!`)),
            list: array().of(
              object().shape({
                type: string().oneOf([
                  'text',
                  'image-single',
                  'image-swipe',
                  'image-list'
                ]),
                text: string().when('type', {
                  is: 'text',
                  then: () =>
                    string()
                      .nullable()
                      .max(
                        25000,
                        i18n._(t`Konten teks maksimal 25000 karakter`)
                      )
                      .transform((v) => v || null),
                  otherwise: () => string().transform(() => undefined)
                }),
                image: string().when('type', {
                  is: 'image-single',
                  then: () =>
                    string()
                      .transform((v) => v || null)
                      .nullable(),

                  otherwise: () => string().transform(() => undefined)
                }),
                imageList: array().when('type', {
                  is: (type) => ['image-swipe', 'image-list'].includes(type),
                  then: () =>
                    array().of(
                      object().shape({
                        imageURL: string()
                          .transform((v) => v || null)
                          .nullable()
                      })
                    ),
                  otherwise: () => string().transform(() => undefined)
                })
              })
            )
          })
        )
        .test(
          'max-20-image-swipe',
          i18n._(t`Maksimal 20 gambar geser untuk setiap Bab Cerita`),
          (value) =>
            value.filter(({ type }) => type === 'image-swipe').length <= 20
        )
        .test(
          'max-20-image-list',
          i18n._(t`Maksimal 20 daftar gambar untuk setiap Bab Cerita`),
          (value) =>
            value.filter(({ type }) => type === 'image-list').length <= 20
        )
        .test(
          'max-20-image',
          i18n._(t`Maksimal 20 gambar untuk setiap Bab Cerita`),
          (value) => value.filter(({ type }) => type === 'image').length <= 20
        )
        .test(
          'max-20-text',
          i18n._(t`Maksimal 20 text untuk setiap Bab Cerita`),
          (value) => value.filter(({ type }) => type === 'text').length <= 20
        )
        .max(20, i18n._(t`Maksimal 20 bab untuk setiap Cerita`))
    })
  })

const createOrUpdate = (i18n) =>
  object()
    .shape({
      sections: array()
        .of(
          lazy(({ type }) =>
            object()
              .shape({
                type: string()
                  .oneOf(
                    Object.keys(sectionValidator(i18n)),
                    i18n._(t`Tipe section harus diisi!`)
                  )
                  .required(i18n._(t`Tipe section harus diisi!`)),
                props: sectionValidator(i18n)[type]?.noUnknown() ?? mixed()
              })
              .noUnknown()
          )
        )
        .min(1, i18n._(t`Minimal 1 section harus diisi!`)),
      slug: string()
        .min(4, i18n._(t`Link halaman konten minimal 4 karakter`))
        .max(120, i18n._(t`Link halaman konten maksimal 120 karakter`))
        .matches(
          /^[a-z0-9-]+$/,
          i18n._(t`Link hanya boleh berisi huruf kecil, angka, dan - (dash).`)
        )
        .required(i18n._(t`Link halaman konten harus diisi!`))
    })
    .noUnknown(true)

const ContentPageValidator = {
  createOrUpdate
}

export default ContentPageValidator
